import React from 'react'

const PlayerBenefits = () => (
  <div
    className="
      bg-blue-900
      flex
      justify-center
      overflow-hidden
      pb-20
      relative
      w-screen
    "
  >
    <div
      className="
        absolute
        bg-white
        h-1/2
        transform
        -translate-y-2/4
        w-screen
      "
    ></div>
    <div
      className="
        bg-blue-100
        inline
        flex
        flex-col
        flex-wrap
        font-sans
        items-center
        justify-center
        px-12
        py-20
        shadow-md
        w-4/5
        max-w-4xl
        space-y-8
        text-blue-800
        tracking-wide
        z-10
      "
    >
      <h2
        className="
          font-black
          leading-snug
          text-4xl
          max-w-2xl
        "
      >What happens behind the scenes?Join our Newsletter</h2>
      <p
        className="
          text-xl
          max-w-2xl
        "
      >Guides, updates, or general thoughts regarding the Podlove and Podcasting.</p>
      <div
        className="
          flex
          flex-wrap
          px-12
          max-w-6xl
        "
      ></div>
    </div>
  </div>
)

export default PlayerBenefits